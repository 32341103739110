import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import gsap from '../lib/gsap.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
		cancelAnimationFrame(timeReq);
	}
};

var winH = $(window).height();
var winW = $(window).width();
var body = $('body');


function nav_show(){
	body.addClass('navopen');
}
function nav_hide(){
	body.removeClass('navopen');
}
function navInit(){
	$('#nav_btn').on('click', function(){
		if( body.hasClass('navopen') ){
			nav_hide();
		}else{
			nav_show();
		}
	});
}


function elFunc(){
	let mm = gsap.matchMedia();
	document.querySelectorAll('.el').forEach((_tar, i) => {
		let _elmc = _tar.querySelectorAll('.elc');
		if( _elmc.length == 0 ){
			_elmc = _tar;
		}
		gsap.fromTo(_elmc,
			{ opacity:0, y:0 },
			{ opacity:1, y:0, 
				duration: 1.2,
				ease: "power4.inOut",
 				stagger: 0.1,
				scrollTrigger: {
					trigger: _tar,
					start: "top bottom-=10%",
					onRefresh: self => self.progress === 1 && self.animation.progress(1)
				}
			}
		);
	});

	const up_scrollTrigger = ScrollTrigger.create({
		trigger: body,
		start: "top top",
		onUpdate: self => {
			const _slfscrl = self.scroll();
			if(self.direction == -1){
				document.body.classList.remove('scrldown');
			}else{
				if(_slfscrl > 10){
					if(self.prevSclrl < _slfscrl-1){
						document.body.classList.add('scrldown');
					}
					self.prevSclrl = _slfscrl;
				}
			}
			scrltop (_slfscrl);
		},
	});
}
const scrltop = (_slfscrl) => {
	if(_slfscrl > 10){
		body.removeClass('scrltop');
		body.addClass('scrl');
	}else{
		body.removeClass('scrl');
		body.addClass('scrltop');
	}
}


async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		resolve();
	})
}


var tar_offset;
function pageScroll(){
	if(window.matchMedia('(max-width:767px)').matches){
		tar_offset = 60;
	}else{
		tar_offset = 60;
	}
	$('a.anchor').on('click', function(e){
		e.preventDefault();
		nav_hide();
		var hrefPageUrl = $(this).prop("href");
		hrefPageUrl = hrefPageUrl.split("#")[0];
		var currentUrl = location.href;
		currentUrl = currentUrl.split("#")[0];
		if( hrefPageUrl == currentUrl ){
			let href = this.hash;
			gsap.to(window, { duration:0.6, scrollTo:{y:href, autoKill:false, offsetY:tar_offset}, ease:'power2'});	
		}
	});
}

function topnav(){
	ScrollTrigger.create({
		trigger: '#kv',
		start: "top top+=1%",
		end: "bottom top",
		onToggle: (self) => {
			if(self.isActive){
				body.addClass('navwh');
			}else{
				body.removeClass('navwh');
			}
		},
	});
}

function topkv(){
	let count = 1;
	const swiper = new Swiper('#topkvSwiper', {
		effect: 'fade',
		speed: 800,
		loop: true,
		autoplay: {
			delay: 3000,
		},
		on: {
			realIndexChange: function(){
				if(count >= 4){
					// document.getElementById('top_copy').classList.add('off');
				}
				count += 1;
			}
		}
	});
}

function topfeature(){
	const item = document.querySelectorAll('.feature');
	const f1 = item[0].querySelector('a');
	const f2 = item[1].querySelector('a');
	let count = 240;
	let callbackId;
	const featureloop = () => {
		if(count >= 240){
			f1.classList.add('active');
			f2.classList.remove('active');
		}
		if(count >= 480){
			f1.classList.remove('active');
			f2.classList.add('active');
			count = 0;
		}
		count += 1;
		callbackId = requestAnimationFrame(featureloop);
	}

	let mm = gsap.matchMedia();
	mm.add("(min-width: 768px)", () => {
		cancelAnimationFrame(callbackId);
	});
	mm.add("(max-width: 767px)", () => {
		featureloop();
	});
}


function singleSlider (){
	$('.swiper').each(function(){
		const _num = $(this).data('num');
		const swiper = new Swiper(this, {
			slidesPerView: 1,
			speed: 600,
			loop: true,
			spaceBetween: 10,
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			navigation: {
				nextEl: '#snext'+_num,
				prevEl: '#sprev'+_num,
			},
		});
	});
}


const expand = (parent, state) => {
	if(state){
		parent.classList.add('open');
	}else{
		parent.classList.remove('open');
	}
	const exc = parent.querySelector('.exc');
	gsap.utils.toArray(exc).forEach(ex => {
		gsap.to(ex,{
			height: (!state) ? 0 : "auto",
			duration:0.3,
			ease: "power2.inOut",
			onComplete: () => {
				ScrollTrigger.refresh();
			}
		});
	});
}
const expand_event = (e) => {
	let parent = e.target.closest('.ex');
	const result = parent.classList.contains('open');
	if (result) {
		expand(parent, false);
	}else{
		// if(window.matchMedia('(min-width:768px)').matches){
		// 	gsap.utils.toArray('.ex').forEach(ex => {
		// 		expand(ex, false);
		// 	});
		// };
		expand(parent, true);
	}
};
function expandFunc() {
	gsap.utils.toArray('.exbtn').forEach(exbtn => {
		let parent = exbtn.closest('.ex');
		expand(parent, false);
		exbtn.removeEventListener('click',expand_event);
		exbtn.addEventListener('click',expand_event);
	});
}


var PageEvents = {
	loadFunc: function(){
		navInit();
	},
	commonFunc: function(){
		this.pjaxReadyFunc();
		winH = $(window).height();
		winW = $(window).width();	
		pageScroll();
		elFunc();
		expandFunc();
		OBSERVER.observeEvents(1);
	},
	pjaxReadyFunc: function(){
	},
	topFunc: async function(){
		body.addClass('top');
		topnav();
		topkv();
		topfeature();
	},
	singleFunc: function(){
		singleSlider();
	}
};

function pjaxSettings(){

	barba.use(barbaPrefetch);

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}
	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
		links.forEach(function (link) {
			link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if(href == null){
			href = el.getAttribute('xlink:href');
		}
		if(el.classList.contains('nopj')){
			return true;
		}
		let site_url = location.protocol + '//' + location.host;
		if (!href.startsWith(site_url)) {
			el.setAttribute('target','_blank');
			return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/,"");
		if (href.startsWith(location.protocol + '//' + location.host)) {
			if (href.indexOf('#') > -1 && extract_hash != url ){
				return false;
			}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
			el.setAttribute('target','_blank');
			return true;
		}
	}

	async function scroll() {
		await new Promise((resolve, reject) => {
			if(location.hash){
				var anchor = document.querySelector( location.hash );
				if(anchor){
					var target = location.hash;
					gsap.to(window, { duration:0, scrollTo:{y:target, autoKill:false, offsetY:tar_offset}, ease:'power2', onComplete:function(){
						resolve();
					}});	
				}else{
					gsap.to(window, { duration:0.6, scrollTo:0, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0.6, scrollTo:0, ease:'power2', onComplete:function(){
					resolve();
				}});
			}
		})
	}

	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('footer', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage navopen scrldown scrltop scrl onevh navwh');
	});

	barba.hooks.beforeEnter((data) => {
		replaceHead(data);
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		gaPush(location.pathname);
	});
	barba.hooks.after((data) => {
		async function callerFun(){
			await scroll();
			pageCheck();
			gsap.to('footer', {opacity:1, duration:0.6})
			gsap.to(data.next.container, {opacity:1, duration:0.6, onComplete:function(){
			}});
		}
		$('#container').imagesLoaded( function() {
			callerFun();
		});
	});
}

function pageCheck(){
	PageEvents.commonFunc();

	if( document.getElementById('top') ){
		PageEvents.topFunc();
	}else{
		body.addClass('inpage');
	}
	if( document.getElementById('single') ){
		PageEvents.singleFunc();
	}
	body.addClass('ready');
}

function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		PageEvents.loadFunc();
		pageCheck();
		pjaxSettings();
	});
}

initialize();

})();
})( jQuery );

}